import 'core-js/stable'
import 'regenerator-runtime/runtime'
import "~/stylesheets/tailwindcss.scss";
import "jquery";
import "popper.js"
import "local-time";
import "@viewabo/fontawesome-pro/js/all";
import "@rails/ujs";

import * as ActiveStorage from "@rails/activestorage";

import.meta.glob('../images/**/*.{png,jpg,jpeg,gif,svg,ico,webp}')

import "~/stylesheets/video_session.scss";

import { defineAsyncComponent } from 'vue'

import { mountVueComponents } from "~/lib/mount_vue_component";

ActiveStorage.start();

const Toast = defineAsyncComponent(() => import("~/pages/application/toast.vue"))
const Register = defineAsyncComponent(() => import("~/pages/standalone/register.vue"))
const SignUp = defineAsyncComponent(() => import("~/pages/application/sessions/sign_up.vue"))
const Login = defineAsyncComponent(() => import("~/pages/application/sessions/login.vue"))
const Confirmation = defineAsyncComponent(() => import("~/pages/application/sessions/confirmation.vue"))
const ForgotPassword = defineAsyncComponent(() => import("~/pages/application/sessions/forgot_password.vue"))
const ResetPassword = defineAsyncComponent(() => import("~/pages/application/sessions/reset_password.vue"))
const InvitedAgent = defineAsyncComponent(() => import("~/pages/application/sessions/invited_agent.vue"))

// TODO: move to zendesk new ui
const ZendeskSetupApiToken = defineAsyncComponent(() => import("~/pages/zendesk/setup_api_token.vue"))

const FreshdeskSetupApiToken = defineAsyncComponent(() => import("~/pages/freshdesk/setup_api_token.vue"))
const ShareVideo = defineAsyncComponent(() => import("~/pages/share/video/index.vue"))

const components = {
  Toast,
  Register,
  SignUp,
  Login,
  Confirmation,
  ForgotPassword,
  ResetPassword,
  InvitedAgent,
  ZendeskSetupApiToken,
  FreshdeskSetupApiToken,
  ShareVideo,
}

mountVueComponents(components);
